import $ from 'jquery'
import { Toast as BootstrapToast } from 'bootstrap-toaster';
import { Toast } from 'bootstrap';
window.bootstrap = {
    Toast
}

$(document).on('click', '#modalQuickView input[type=radio]', function() {
    $(this).parent().parent().parent().find('.productOptionCaption').html($(this).val());

    var is_out_of_stock = $('#modalQuickView input[name=is_out_of_stock]').val();
    var sel_arr = [];

    $('#modalQuickView input[type=radio]').each(function() {
        if ($(this).prop("checked")) {
        sel_arr.push($(this).val());
        }
    });
   
    if(0 == is_out_of_stock){
        var quantity = parseInt($('#modalQuickView #input-quantity').val());
        $.each(sel_arr, function(i, sel) {
            $.each(product_spec, function(j, spec) {
                var spec_value = spec.spec_value.split(',');
                var result = spec_value.some(function(item) {
                    return item === sel;
                });
                if(result){
                    $('#modalQuickView input[type=radio]').each(function() {
                        var val = $(this).val();
                        var result1 = spec_value.some(function(item) {
                            return item === val;
                        });
                        if(result1){
                            $(this).attr("disabled",quantity > spec.quantity ? true : false);
                        }
                    });
                }
            });
        });
    }

    $('#modalQuickView input[name="spec_value"]').val('');
    $.each(product_spec, function(j, spec) {
        if(spec.spec_value == sel_arr.join(",")) {
            $('#modalQuickView #product-price').text(spec.price);
            $('#modalQuickView input[name="spec_value"]').val(spec.spec_value);
            if(spec.image){
                $('#modalQuickView .img-fluid').attr('src',spec.image);
            }
            return;
        }
    });
});

$(document).on('click', '#size-chart-link', function() {
    $('#product-desc .nav-link').removeClass('active');
    
    $('#product-desc a[href="#helpTabSizeChart"]').addClass('active');
    
    $('#product-desc .tab-pane').removeClass('show active');
    
    $('#product-desc #helpTabSizeChart').addClass('show active');
});

$(document).on('click', '#product-form input[type=radio]', function() {

    $(this).parent().parent().parent().find('.productOptionCaption').html($(this).val());

    var is_out_of_stock = $('#product-form input[name=is_out_of_stock]').val();
    var sel_arr = [];

    $('#product-form input[type=radio]').each(function() {
        if ($(this).prop("checked")) {
        	sel_arr.push($(this).val());
        }
    });
   
    if(0 == is_out_of_stock){
        var quantity = parseInt($('#product-form #input-quantity').val());
        $.each(sel_arr, function(i, sel) {
            $.each(product_spec, function(j, spec) {
                var spec_value = spec.spec_value.split(',');
                var result = spec_value.some(function(item) {
                    return item === sel;
                });
                if(result){
                    $('#product-form input[type=radio]').each(function() {
                        var val = $(this).val();
                        var result1 = spec_value.some(function(item) {
                            return item === val;
                        });
                        if(result1){
                            $(this).attr("disabled",quantity > spec.quantity ? true : false);
                        }
                    });
                }
            });
        });
    }
    
    $('#product-form input[name="spec_value"]').val('');
    $.each(product_spec, function(j, spec) {
        if(spec.spec_value == sel_arr.join(",")) {
            $('#product-form #product-price').text(spec.price);
            $('#product-form input[name="spec_value"]').val(spec.spec_value);
            if(spec.image){
                $('#product-spec-img').remove();
                $('#product-detail #productSlider a.flickity-cell.is-selected').attr('style','transform: translateX(0%); opacity: 0;');
                $('#product-detail #productSlider a.flickity-cell.is-selected').attr('aria-hidden','true');
                $('#product-detail #productSlider a.flickity-cell.is-selected').removeClass('is-selected');

                $('#product-detail #productSlider a.flickity-cell.is-selected').removeClass('is-selected');
                $('#product-detail .flickity-nav .flickity-viewport .flickity-cell').removeClass('is-nav-selected');

                $('#product-detail #productSlider .flickity-viewport .flickity-slider').append('<a href="#" data-bigpicture=\'{"imgSrc": "'+spec.image+'"}\' id="product-spec-img" class="flickity-cell is-selected" style="transform: translateX(0%); opacity: 1;"><img src="'+spec.image+'" alt="" class="card-img-top"></a>');
            }
            return;
        }
    });
});

$(document).on('click', '.flickity-nav .flickity-viewport .flickity-cell', function() {
  $('#product-spec-img').remove();
});



let Product = {
	'quickView': function(product_id) {
		$('#modalQuickView').remove();
		$.ajax({
			url: '/product/quick-view',
			data: {'product_id':product_id},
			dataType: 'json',
			type: 'post',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				$('body').append('<div class="modal fade" id="modalQuickView" tabindex="-1" role="dialog" aria-hidden="true">' + json.data + '</div>');
				Modal.getOrCreateInstance($('#modalQuickView')).show();
			}
		});
	},
	'search': function(keywords = '',is_search = 0) {
		if(0 == is_search){
			$('#modalSearch').remove();
		}
		$.ajax({
			url: '/search/list',
			data: {'keywords':keywords},
			dataType: 'json',
			type: 'get',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				if(0 == is_search){
					$('body').append('<div class="offcanvas offcanvas-end" id="modalSearch" tabindex="-1" role="dialog" aria-hidden="true">' + json.data + '</div>');
					Offcanvas.getOrCreateInstance($('#modalSearch')).show();
				}else{
					$('#modalSearch').html(json.data);
				}
			}
		});
	},
};

let Cart = {
	'add': function(product_id, spec_value='', quantity=1) {
		$.ajax({
			url: '/cart/add',
			type: 'post',
			data: {'product_id':product_id,'spec_value':spec_value,'quantity':(typeof(quantity) != 'undefined' ? quantity : 1)},
			dataType: 'json',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				if (json.data.redirect) {
					location = json.data.redirect;
					return ;
				}
				if (json.success) {
					$('#shopping-cart-total').attr('data-cart-items',json.data.total_quantity);
					BootstrapToast.create({
						title: '',
						message: json.msg,
						status: TOAST_STATUS.SUCCESS,
						timeout: 5000
					});
				}
			},
	        error: function(xhr, ajaxOptions, thrownError) {
	            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
	        }
		});
	},
	'quickAdd': function(product_id, is_product_spec) {
		if( 1 == is_product_spec){
			Product.quickView(product_id);
		}else{
			Cart.add(product_id,'',1);
		}
	},
	'update': function(cart_id,obj) {
		var quantity = $(obj).val();
		$.ajax({
			url: '/cart/edit',
			type: 'post',
			data: {'cart_id':cart_id,'quantity':(typeof(quantity) != 'undefined' ? quantity : 1)},
			dataType: 'json',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				$.ajax({
					url: '/cart/info',
					method: 'GET',
					success: function(json) {
						$('#modalShoppingCart').html(json.data);
					}
				  });
				$('#shopping-cart-total').attr('data-cart-items',json.data.total_quantity);
			},
	        error: function(xhr, ajaxOptions, thrownError) {
	            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
	        }
		});
	},
	'remove': function(cart_id) {
		$.ajax({
			url: '/cart/remove',
			type: 'post',
			data: {'cart_id':cart_id},
			dataType: 'json',
			success: function(json) {
				if(!json.success){		
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				$.ajax({
					url: '/cart/info',
					method: 'GET',
					success: function(json) {
						$('#modalShoppingCart').html(json.data);
					}
				  });
				$('#shopping-cart-total').attr('data-cart-items',json.data.total_quantity);
			},
	        error: function(xhr, ajaxOptions, thrownError) {
	            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
	        }
		});
	},
	'show': function(){
		$('#modalShoppingCart').remove();
		$.ajax({
			url: '/cart/info',
			dataType: 'json',
			type: 'get',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				$('body').append('<div class="offcanvas offcanvas-end" id="modalShoppingCart" tabindex="-1" role="dialog" aria-hidden="true">' + json.data + '</div>');
				Offcanvas.getOrCreateInstance($('#modalShoppingCart')).show();
			}
		});
	}
};

let Wishlist = {
	'add': function(product_id) {
		$.ajax({
			url: '/customer/wishlist/add',
			type: 'post',
			data: {'product_id':product_id},
			dataType: 'json',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				BootstrapToast.create({
					title: '',
					message: json.msg,
					status: TOAST_STATUS.SUCCESS,
					timeout: 5000
				});
			},
	        error: function(xhr, ajaxOptions, thrownError) {
	            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
	        }
		});
	},
	'remove': function(wishlist_id) {
		$.ajax({
			url: '/customer/wishlist/delete',
			type: 'post',
			data: {'wishlist_id':wishlist_id},
			dataType: 'json',
			success: function(json) {
				if(!json.success){
					BootstrapToast.create({
						title: 'Error',
						message: json.msg,
						status: TOAST_STATUS.DANGER,
						timeout: 5000
					});
					return;
				}
				location.reload();
			},
	        error: function(xhr, ajaxOptions, thrownError) {
	            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
	        }
		});
	}
};

$(document).on('click', '#reviewList .pagination a', function (e) {
    e.preventDefault();
    $('#reviewList').load(this.href);
});

$(document).on('click', '#review-order a', function (e) {
    e.preventDefault();
    $('#review-order-text').html($(this).html());
    $('#reviewList').load(this.href);
});

$(document).on('click', '#form-review #btn-write-review', function (e) {
    if (!$('#form-review')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-review')[0].classList.add('was-validated');
        return;
    }

    $.ajax({
        url: '/review/write',
        type: 'post',
        dataType: 'json',
        data: $("#form-review").serialize(),
        beforeSend: function () {
            $('#btn-write-review').attr('disabled', "true");
        },
        complete: function () {
            $('#btn-write-review').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-review .error').remove();
            if (!json.success) {
                BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }

            if (json.success) {
                BootstrapToast.create({
                    title: '',
                    message: json.msg,
                    status: TOAST_STATUS.SUCCESS,
                    timeout: 5000
                });
            }
        }
    });
});

$(document).on('click', '#form-newsletter #btn-newsletter', function (e) {
    if (!$('#form-newsletter')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-newsletter')[0].classList.add('was-validated');
        return;
    }

    $.ajax({
        url: '/newsletter/index',
        type: 'post',
        dataType: 'json',
        data: $("#form-newsletter").serialize(),
        beforeSend: function () {
            $('#btn-newsletter').attr('disabled', "true");
        },
        complete: function () {
            $('#btn-newsletter').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-newsletter .error').remove();
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            if (json.success) {
                wolooo.BootstrapToast.create({
                    title: '',
                    message: json.msg,
                    status: TOAST_STATUS.SUCCESS,
                    timeout: 5000
                });
            }
        }
    });
});

$(document).on('click', '#form-login #btn-login', function (e) {
    if (!$('#form-login')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-login')[0].classList.add('was-validated');
        return;
    }

    if (1 == $('input[name="is_remember"]:checked').val()) {
        localStorage.setItem('email', $('#loginEmail').val());
    } else {
        localStorage.removeItem('email');
    }

    $.ajax({
        url: '/login/auth',
        type: 'post',
        dataType: 'json',
        data: $("#form-login").serialize(),
        beforeSend: function () {
            $('#form-login #btn-login').attr('disabled', "true");
        },
        complete: function () {
            $('#form-login #btn-login').removeAttr("disabled");
        },
        success: function (json) {

            $('#form-login .error').remove();
            if (!json.success) {
                $('#form-login').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                location = json.data.redirect;
            }
        }
    });
});

$(document).on('click', '#form-register #btn-register', function (e) {

    if ($('#registerPassword').val() != $('#registerPasswordConfirm').val()) {
        $('#registerPasswordConfirm')[0].setCustomValidity('is-invalid');
    } else {
        $('#registerPasswordConfirm')[0].setCustomValidity('');
    }

    if (!$('#form-register')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-register')[0].classList.add('was-validated');
        return;
    }
    $.ajax({
        url: '/register/save',
        type: 'post',
        dataType: 'json',
        data: $("#form-register").serialize(),
        beforeSend: function () {
            $('#form-register #btn-register').attr('disabled', "true");
        },
        complete: function () {
            $('#form-register #btn-register').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-register .error').remove();
            if (!json.success) {
                $('#form-register').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                // console.log(json.data.redirect);
                location = json.data.redirect;
            }
        }
    });
});

$(document).on('click', '#form-forgotten #btn-forgotten', function (e) {
    if (!$('#form-forgotten')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-forgotten')[0].classList.add('was-validated');
        return;
    }
    $.ajax({
        url: '/forgotten/reset',
        type: 'post',
        dataType: 'json',
        data: $("#form-forgotten").serialize(),
        beforeSend: function () {
            $('#form-forgotten #btn-forgotten').attr('disabled', "true");
        },
        complete: function () {
            $('#form-forgotten #btn-forgotten').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-forgotten .error').remove();
            if (!json.success) {
                $('#form-forgotten').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                location = json.data.redirect;
            }
        }
    });
});

$(document).on('click', '#form-reset-password #btn-reset-password', function (e) {
    if (!$('#form-reset-password')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-reset-password')[0].classList.add('was-validated');
        return;
    }
    $.ajax({
        url: '/reset_password/password',
        type: 'post',
        dataType: 'json',
        data: $("#form-reset-password").serialize(),
        beforeSend: function () {
            $('#form-reset-password #btn-reset-password').attr('disabled', "true");
        },
        complete: function () {
            $('#form-reset-password #btn-reset-password').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-reset-password .error').remove();
            if (!json.success) {
                $('#form-reset-password').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                location = json.data.redirect;
            }
        }
    });
});

$(document).on('click', '#form-edit-customer #btn-edit', function (e) {
    if (!$('#form-edit-customer')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-edit-customer')[0].classList.add('was-validated');
        return;
    }
    $.ajax({
        url: '/customer/edit/save',
        type: 'post',
        dataType: 'json',
        data: $("#form-edit-customer").serialize(),
        beforeSend: function () {
            $('#form-edit-customer #btn-edit').attr('disabled', "true");
        },
        complete: function () {
            $('#form-edit-customer #btn-edit').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-edit-customer .error').remove();
            if (!json.success) {
                $('#form-edit-customer').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                wolooo.BootstrapToast.create({
                    title: '',
                    message: json.msg,
                    status: TOAST_STATUS.SUCCESS,
                    timeout: 5000
                });
            }
        }
    });
});

$(document).on('click', '#form-address #btn-address', function (e) {
    if (!$('#form-address')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-address')[0].classList.add('was-validated');
        return;
    }
    $.ajax({
        url: '/customer/address/save',
        type: 'post',
        dataType: 'json',
        data: $("#form-address").serialize(),
        beforeSend: function () {
            $('#form-address #btn-address').attr('disabled', "true");
        },
        complete: function () {
            $('#form-address #btn-address').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-address .error').remove();
            if (!json.success) {
                $('#form-address').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            if (json.success) {
                wolooo.BootstrapToast.create({
                    title: '',
                    message: json.msg,
                    status: TOAST_STATUS.SUCCESS,
                    timeout: 5000
                });
            }
        }
    });
});

$(document).on('click', '.btn-del-address', function (e) {
    let address_id = $(this).data('id');
    let obj = $(this);
    $.ajax({
        url: '/customer/address/delete',
        type: 'post',
        dataType: 'json',
        data: { 'address_id': address_id },
        beforeSend: function () {
            obj.attr('disabled', "true");
        },
        complete: function () {
            obj.removeAttr("disabled");
        },
        success: function (json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            wolooo.BootstrapToast.create({
                title: '',
                message: json.msg,
                status: TOAST_STATUS.SUCCESS,
                timeout: 5000
            });
            $('#address-box-' + address_id).remove();
        }
    });
});

$(document).on('click', '#form-contact #btn-contact', function (e) {
    if (!$('#form-contact')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-contact')[0].classList.add('was-validated');
        return;
    }

    let obj = $(this);
    $.ajax({
        url: '/contact/save',
        type: 'post',
        dataType: 'json',
        data: $("#form-contact").serialize(),
        beforeSend: function () {
            obj.attr('disabled', "true");
        },
        complete: function () {
            obj.removeAttr("disabled");
        },
        success: function (json) {
            $('#form-contact .error').remove();
            if (!json.success) {
                $('#form-contact').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            wolooo.BootstrapToast.create({
                title: '',
                message: json.msg,
                status: TOAST_STATUS.SUCCESS,
                timeout: 5000
            });
        }
    });
});

$(document).on('click', '#form-activate-account #btn-activate-account', function (e) {
    if ($('#passwordActivate').val() != $('#passwordConfirmActivate').val()) {
        $('#passwordConfirmActivate')[0].setCustomValidity('is-invalid');
    } else {
        $('#passwordConfirmActivate')[0].setCustomValidity('');
    }
    
    if (!$('#form-activate-account')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-activate-account')[0].classList.add('was-validated');
        return;
    }

    $.ajax({
        url: '/customer/activate/activate_account',
        type: 'POST',
        dataType: 'json',
        data: $("#form-activate-account").serialize(),
        beforeSend: function () {
            $('#btn-activate-account').attr('disabled', true);
        },
        complete: function () {
            $('#btn-activate-account').attr('disabled', false);
        },
        success: function (json) {
            $('#form-activate-account .error').remove();

            if (!json.success) {
                $('#form-activate-account').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
            } else {
                location = json.data.redirect;
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            confirmError(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
    });
});

$(document).on('click', '#form-activate-account #btn-decline-invitation', function (e) {
    $.ajax({
        url: '/customer/activate/decline_invitation',
        type: 'POST',
        dataType: 'json',
        beforeSend: function () {
            $('#btn-decline-invitation').attr('disabled', true);
        },
        complete: function () {
            $('#btn-decline-invitation').attr('disabled', false);
        },
        success: function (json) {
            $('#form-activate-account .error').remove();

            if (!json.success) {
                $('#form-activate-account').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
            } else {
                location = json.data.redirect;
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            confirmError(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
    });
});

$(document).on('click', '#blogReviewList .pagination a', function (e) {
    e.preventDefault();
    $('#blogReviewList').load(this.href);
});

$(document).on('click', '#blog-review-order a', function (e) {
    e.preventDefault();
    $('#blog-review-order-text').html($(this).html());
    $('#blogReviewList').load(this.href);
});

$(document).on('click', '#form-blog-review #btn-blog-review', function (e) {
    if (!$('#form-blog-review')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        $('#form-blog-review')[0].classList.add('was-validated');
        return;
    }

    $.ajax({
        url: '/blog_review/write',
        type: 'post',
        dataType: 'json',
        data: $("#form-blog-review").serialize(),
        beforeSend: function () {
            $('#btn-blog-review').attr('disabled', "true");
        },
        complete: function () {
            $('#btn-blog-review').removeAttr("disabled");
        },
        success: function (json) {
            $('#form-blog-review .error').remove();
            if (!json.success) {
                $('#form-blog-review').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            wolooo.BootstrapToast.create({
                title: '',
                message: json.msg,
                status: TOAST_STATUS.SUCCESS,
                timeout: 5000
            });
            $('#reviewName').val('');
            $('#reviewEmail').val('');
            $('#reviewText').val('');
        }
    });
});

$(document).on('click', '#form-cart-product .btn-remove-cart', function (e) {
    var cart_id = $(this).data('id');
    $.ajax({
        url: '/cart/remove',
        type: 'post',
        dataType: 'json',
        data: {'cart_id':cart_id},
        success: function (json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            $.ajax({
                url: '/cart/index',
                method: 'GET',
                success: function(json) {
                    $('#shippingCart').html(json.data);
                }
              });
              wolooo.BootstrapToast.create({
                title: '',
                message: json.msg,
                status: TOAST_STATUS.SUCCESS,
                timeout: 5000
            });
            $('#shopping-cart-total').attr('data-cart-items',json.data.total_quantity);            
        }
    });
});

$(document).on('change', '.cart-quantity', function (e) {
    let cart_id = $(this).data('id');
    let quantity = $(this).val();
    $.ajax({
        url: '/cart/edit',
        type: 'post',
        data: {'cart_id':cart_id,'quantity':(typeof(quantity) != 'undefined' ? quantity : 1)},
        dataType: 'json',
        success: function(json) {
            if(!json.success){
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            $.ajax({
                url: '/cart/index',
                method: 'GET',
                success: function(json) {
                    $('#shippingCart').html(json.data);
                }
              });

              wolooo.BootstrapToast.create({
                title: '',
                message: json.msg,
                status: TOAST_STATUS.SUCCESS,
                timeout: 5000
            });

            $('#shopping-cart-total').attr('data-cart-items',json.data.total_quantity);    
        },
        error: function(xhr, ajaxOptions, thrownError) {
            alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        }
    });
});

$(document).on('click', '#form-cart-coupon #btn-cart-coupon', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let coupon = $('#cartCouponCode').val();
    if(!coupon){
        return false;
    }
    $.ajax({
        url: '/coupon/confirm',
        type: 'post',
        dataType: 'json',
        data: {'coupon':coupon},
        success: function (json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            $.ajax({
                url: '/cart/index',
                method: 'GET',
                success: function(json) {
                    $('#shippingCart').html(json.data);
                }
              });
        }
    });
});

$(document).on('click', '#form-cart-coupon #coupon-unconfirm', function (e) {
    $.ajax({
        url: '/coupon/unconfirm',
        type: 'get',
        dataType: 'json',
        success: function (json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            $.ajax({
                url: '/cart/index',
                method: 'GET',
                success: function(json) {
                    $('#shippingCart').html(json.data);
                }
              });
        }
    });
});

$("#checkoutShippingCountry,#checkoutBillingCountry").change(function () {
    let country_code = $(this).val();
    let postcode_required = $(this).find("option:selected").data('postcode-required');
	let type;
    if($(this).attr('id') == 'checkoutShippingCountry'){
      type = 'Shipping';
    }else{
      type = 'Billing';
    }

    if (1 == postcode_required) {
      $('#checkout'+type+'ZIPLabel').html($('#checkout'+type+'ZIPLabel').data('label') + ' *');
      $('#checkout'+type+'ZIP').attr('required', 'required');
    } else {
      $('#checkout'+type+'ZIPLabel').html($('#checkout'+type+'ZIPLabel').data('label'));
      $('#checkout'+type+'ZIP').removeAttr('required');
    }
    
    let html = '';
    $.each(region, function (idx, country) {
      if (country_code == country.code) {
        $.each(country.zones, function (idz, zone) {
          html += "<option value='" + zone.code + "'>" + zone.name + "</option>";
        });
        return;
      }
    });
    
    $('#checkout'+type+'Zone').html(html);
    $('#checkout'+type+'Zone').val('');

    if (html == '') {
      $('#checkout'+type+'ZoneForm').hide();
      $('#checkout'+type+'ZoneLabel').html($('#checkout'+type+'ZoneLabel').data('label'));
      $('#checkout'+type+'Zone').removeAttr('required');
    } else {
      $('#checkout'+type+'ZoneForm').show();
      $('#checkout'+type+'ZoneLabel').html($('#checkout'+type+'ZoneLabel').data('label') + ' *');
      $('#checkout'+type+'Zone').attr('required', 'required');
    }
    if(type == 'Shipping'){
        getShippingMethod();
    }
});

$("#checkoutShippingZone").change(function () {
    getShippingMethod();
});

$(document).on('click', '#checkoutShippingSameAddress', function (e) {
    if ($('input[name="is_shipping_same"]:checked').val()) {
        $('#checkoutShippingAddressCollapse').show();
        $('input[name="bill_first_name"]').attr('required', 'required');
        $('input[name="bill_last_name"]').attr('required', 'required');
        $('input[name="bill_telephone"]').attr('required', 'required');
        $('input[name="bill_address"]').attr('required', 'required');
        $('input[name="bill_city"]').attr('required', 'required');
        $('select[name="bill_country_code"]').attr('required', 'required');
    } else {
        $('#checkoutShippingAddressCollapse').hide();
        $('input[name="bill_first_name"]').removeAttr('required', 'required');
        $('input[name="bill_last_name"]').removeAttr('required', 'required');
        $('input[name="bill_telephone"]').removeAttr('required', 'required');
        $('input[name="bill_address"]').removeAttr('required', 'required');
        $('input[name="bill_city"]').removeAttr('required', 'required');
        $('select[name="bill_country_code"]').removeAttr('required', 'required');
    }
});

$(document).on('click', 'input[name="shipping_method"]', function (e) {
    getCheckoutTotal();
    $("#errorShipping").css("display", "none");
});

$(document).on('click', 'input[name="payment_method"]', function (e) {
    $("#errorPayment").css("display", "none");
});

function getCheckoutTotal(){
    var country = $('select[name="shipping_country_code"]').val();
    var zone = $('select[name="shipping_zone_code"]').val();
    var shipping_method_id = $('input[name="shipping_method"]:checked').val();

    $.ajax({
        url: '/checkout/total',
        data: {'shipping_method_id':shipping_method_id,'country':country,'zone':zone},
        method: 'POST',
        success: function(json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
            $('#checkoutTotal').html(json.data);
        }
    });
}

function getShippingMethod(){
    let country = $('#checkoutShippingCountry').val();
    let zone = $('#checkoutShippingZone').val();

    let sel_val = $('input[name="shipping_method"]:checked').val();

    if(country){
        $.ajax({
            url: '/checkout/shipping',
            data: {'country':country,'zone':zone},
            method: 'GET',
            success: function(json) {
                $('#checkoutShipping').html(json.data);
                $("#checkoutShipping input[name='shipping_method']").each(function(){
                    if($(this).val() == sel_val){
                        $(this).attr('checked','checked')
                    }
                });
                getCheckoutTotal();
            }
        });
    }
}

$(document).on('click', '#btn-checkout-submit', function (e) {
    if (!$('#formCheckout')[0].checkValidity()) {
        e.preventDefault();
        e.stopPropagation();

        if (!$("input:radio[name='shipping_method']").is(":checked")) {
            $("#errorShipping").css("display", "block");
        } else {
            $("#errorShipping").css("display", "none");
        }   
        
        if (!$("input:radio[name='payment_method']").is(":checked")) {
            $("#errorPayment").css("display", "block");
        } else {
            $("#errorPayment").css("display", "none");
        }

        $('#formCheckout')[0].classList.add('was-validated');
        return;
    }

    $.ajax({
        url: '/checkout/submit',
        type: 'post',
        dataType: 'json',
        data: $("#formCheckout").serialize(),
        beforeSend: function () {
            $('#btn-checkout-submit').attr('disabled', "true");
        },
        complete: function () {
            $('#btn-checkout-submit').removeAttr("disabled");
        },
        success: function (json) {
            $('#formCheckout .error').remove();
            if (!json.success) {
                $('#formCheckout').prepend('<div class="error alert alert-primary" role="alert">' + json.msg + '</div>');
                return;
            }
            location = json.data.redirect;
        }
    });
});


function productFilter() {
    let params = {};
    $("#form-filter .form-check-input").each(function(){
        if($(this).prop("checked")){
            let key = $(this).data('name');
            if(params[key]){
                params[key] = params[key]+','+$(this).val();
            }else{
                params[key] = $(this).val();
            }
        }
    });
    
    let keywords = $('#form-filter input[name=keywords]').val();
    if(keywords){
        params['keywords'] = keywords;
    }

    let url = '';
    $.each(params, function (key, val) {
        if(url){
            url=url+'&'+key+'='+val;
        }else{
            url=key+'='+val;
        }
    });

    let category_id = $('#form-filter input[name=category_id]').val();
    if(category_id){
        params['category_id'] = category_id;
    }
    let show_type = $('#form-filter input[name=show_type]').val();
    if(category_id){
        params['show_type'] = show_type;
    }

    let sort_by = $('#product-sort').val();
    if(sort_by){
        params['sort_by'] = sort_by;
    }

    let newUrl = `${location.protocol}//${location.host}${location.pathname}`+'?'+url;
    history.pushState({}, '', newUrl);

    $.ajax({
        url: '/product_filter/index',
        type: 'get',
        dataType: 'json',
        data: params,
        success: function (json) {
            if (!json.success) {
                wolooo.BootstrapToast.create({
                    title: 'Error',
                    message: json.msg,
                    status: TOAST_STATUS.DANGER,
                    timeout: 5000
                });
                return;
            }
           $('#form-filter').html(json.data.filter);
           $('#product-list').html(json.data.product_list);
        }
    });
}

$('#form-filter').delegate('.form-check-input', 'change', function(e) {
    productFilter();
});

$(document).on('click', '.sel-filter', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).parent().find('span').remove();

    let sel_key = $(this).data('key');
    let sel_value = $(this).data('value');
    $("#form-filter .form-check-input").each(function(){
        let key = $(this).data('name');
        if(sel_key == key && $(this).val() == sel_value){
            $(this).prop("checked",false);
        }
    });
    productFilter();
});

$(document).on('change', '#product-sort', function (e) {
    productFilter();
});

$(document).on('change', '.size-local', function (e) {
    let previous_value  = $('input[name="select_local_val"]').val();
    $('input[name="select_local_val"]').val($(this).val());
    if('' != previous_value || '' == $(this).val()){
        $('#size-info-table thead th:first-child').remove();
        $('#size-info-table tbody tr').each(function() {
            $(this).find('td:first-child').remove();
        });
    }

    let local = $(this).val();
    if(local){
        $('#size-info-table thead tr').prepend('<th>'+local+'</th>');
        $('#size-info-table tbody tr').each(function() {
            var default_value = $(this).find('td:first').text(); 
            let tr_obj = $(this);
            $.each(size_info.local_size[local], function (key, val) {
                if(default_value == val.name){
                    tr_obj.prepend('<td>'+val.correspond+'</td>');
                }
            });
        });
    }
});

$(document).on('click', '.size-unit', function() {
    let id = $(this).attr('id');

    let size_list;
    if(id == 'cmOne'){
      size_list = size_info.size_cm;
    }
    if(id == 'inOne'){
      size_list = size_info.size_inch;
    }

    let local_value  = $('input[name="select_local_val"]').val();

    $('#size-info-table tbody').empty();
    $.each(size_list, function (key1, row) {
        let td_str = '';
        $.each(row, function (key2, item) {
            if(local_value){
              $.each(size_info.local_size[local_value], function (lkey, lval) {
                if(item == lval.name){
                  td_str+='<td>'+lval.correspond+'</td>';
                }
              });
            }
            td_str+='<td>'+item+'</td>';
        });
        $('#size-info-table tbody').append('<tr>'+td_str+'</tr>');
    });
});

window.wolooo = {
    Cart,Wishlist,Product,BootstrapToast
}

window.$ = $;